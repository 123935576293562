<template>
  <div>
    <div style="margin: 10px 180px">
      <el-radio-group v-model="radio">
        <el-radio :label="1">{{$t('public.Enable')}}</el-radio>
        <el-radio :label="0">{{$t('public.Forbidden')}}</el-radio>
      </el-radio-group>
    </div>
    <p style="padding-left: 122px; padding-top: 10px;font-size:14px;">{{$t('System.IosConfig.ColleConfig.DeviceInformation')}}：</p>
    <div style="margin: 5px 0">
      <el-checkbox
        :disabled="radio == 0"
        @change="changeEvent"
        true-label="1"
        false-label="0"
        v-model="devbasicinfo"
        style="padding-left: 180px"
      >
        <span>{{$t('System.IosConfig.ColleConfig.BasicInformation')}}</span>
      </el-checkbox>
      <el-tooltip placement="right" effect="light">
        <div slot="content" style="color: #807e7e">
          {{$t('System.IosConfig.ColleConfig.BrMessage1')}}<br />{{$t('System.IosConfig.ColleConfig.BrMessage2')}}<br />{{$t('System.IosConfig.ColleConfig.BrMessage3')}}<br />{{$t('System.IosConfig.ColleConfig.BrMessage4')}}<br />{{$t('System.IosConfig.ColleConfig.BrMessage5')}}
        </div>
        <img
          src="@/assets/question.png"
          alt=""
          srcset=""
          style="padding-left: 8px"
        />
      </el-tooltip>
    </div>
    <p style="padding-left: 122px; padding-top: 10px;font-size:14px;">{{$t('System.IosConfig.ColleConfig.CollectionCycle')}}：</p>
    <el-input
    @input="handleInput" maxlength="4"
      :disabled="radio == 0"
      @change="changeInput"
      v-model.number="form.period"
      :max="1440"
      :min="5"
      size="small"
      style="padding-left: 180px; width: 360px; margin: 5px 0"
    ></el-input>
    <span style="margin-left: 5px;color:#807e7e;">{{$t('System.IosConfig.ColleConfig.CheckBoxmessage4')}}</span>
    <div>
      <el-button
        style="width: 80px; margin: 20px 0 0 180px"
        @click="saveSubmit"
        size="small"
        type="primary"
        >{{$t('public.Save')}}</el-button
      >
    </div>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: {
    dialogInfo,
  },
  data() {
    return {
      titleInfo: "",
      visibaelFlag: false,
      radio: "",
      devbasicinfo: "",
      devactioninfo: "",
      content: [],
      form: {
        // 系统权限限制
        use: false,
        period: "",
      },
    };
  },
  created() {
    this.searchList();
  },
  methods: {
    handleInput(value) {  
      const regex = /^[0-9]*$/;  
      if (!regex.test(value)) {  
        this.form.period = '';  
      }  
    },
    changeInput(){
      if(this.form.period < 5) {
        this.form.period = ''
      }else if(this.form.period>1440){
        this.form.period = ''
      }
    },
    changeEvent(val) {
      if (val == 1) {
        this.content.push("dev_basic_info");
      } else {
        this.content.forEach((item, index) => {
          if (item == "dev_basic_info") {
            this.content.splice(index, 1);
          }
        });
      }
      console.log(this.content);
    },
    async searchList() {
      const res = await this.$axios.post(
        "/httpServe/ios/collection/selectOne",
        {},
        true
      );
      console.log(res);
      this.radio = res.data.use;
      this.form.period = res.data.period;
      this.content = res.data.content;
      this.content.forEach((item) => {
        if (item == "dev_basic_info") {
          this.devbasicinfo = "1";
        }
        if (item == "dev_action_info") {
          this.devactioninfo = "1";
        }
      });
    },
    async saveSubmit() {
      if(this.form.period == ""){
        // this.titleInfo = "请输入采集周期";
        // this.visibaelFlag = true;
        this.$message({
            message: this.$t('System.IosConfig.ColleConfig.Error'),
            type: "error",
            offset: 100,
          });
        return
      }
      let params = {
        use: this.radio,
        period: this.form.period *1,
        content: this.content,
      };
      const res = await this.$axios.post(
        "/httpServe/ios/collection/update",
        params,
        true
      );
      if (res.data == 1) {
        // this.titleInfo = "保存成功";
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset:100
        });
      }
    },
    determine() {
      this.visibaelFlag = false;
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    test(val) {},
  },
};
</script>

<style>
</style>