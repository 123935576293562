<template>
  <div style="height: 425px; overflow: auto">
    <div class="box">
      <p style="padding-left: 132px; padding-top: 10px;font-size:14px;">威胁监测：</p>
      <div>
        <el-form
          ref="form"
          :model="form"
          label-width="151px"
          class="demo-ruleForm"
          style="padding: 0 86px"
        >
          <el-form-item label="应用威胁" style="font-size:14px;">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                监测应用自身威胁情况
              </div>
              <img src="@/assets/question.png" alt="" srcset="" style="padding-left: 8px" />
            </el-tooltip>
            <el-switch @change="test" active-value="1" inactive-value="0" style="padding-left: 15px" v-model="dataArray.use"></el-switch>
          </el-form-item>
          <div class="bigbigbox" v-show="dataArray.use == 1" style="padding-left: 47px">
            <div
              v-for="(item, index) in listData"
              :key="index"
              class="item"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 10px;
              ">
              <div style="
                  width: 130px;
                  text-align: right;
                  color: #606266;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                ">
                {{
                  item.name == "inject"
                    ? "注入攻击："
                    : item.name == "debug"
                    ? "调试行为："
                    : item.name == "domain"
                    ? "域名欺诈："
                    : item.name == "signature"
                    ? "应用破解："
                    : item.name == "httpsCA"
                    ? "HTTPS劫持："
                    : item.name == "wifiProxy"
                    ? "HTTP代理："
                    : item.name == "frameAttach"
                    ? "框架软件："
                    : item.name == "rootStatus"
                    ? "ROOT/越狱："
                    : item.name == "vm"
                    ? "模拟器："
                    : ""
                }}
              </div>
              <div>
                <el-checkbox v-model="item.collect" @change="zrgjcai" style="margin-left: 15px">采集行为</el-checkbox>
              </div>
              <div style="
                  margin-left: 50px;
                  color: #606266;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                ">
                响应动作：
                <el-radio-group v-model="item.action" @change="zrgjcai">
                  <el-radio :label="4" @click.native.prevent="checkboxRadio(item,4)" style="margin-left: 15px">告警提示</el-radio>
                  <el-radio :label="8" @click.native.prevent="checkboxRadio(item,8)">清除数据</el-radio>
                  <el-radio :label="2" @click.native.prevent="checkboxRadio(item,2)">退出应用</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <el-button
        style="width: 80px; margin: 20px 0 0 183px"
        @click="saveSubmit"
        size="small"
        type="primary"
        >保存</el-button
      >
    </div>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: {
    dialogInfo
  },
  data() {
    return {
      titleInfo: '',
      visibaelFlag: false,
      wxflag: false,
      form: {},
      // 多选框/单选
      checked1: '',
      checked2: '',
      radio: '',
      dataArray: {
        use: '1'
      },
      listData: [
        {
          name: 'inject',
          collect: false,
          action: '0'
        },
        {
          name: 'frame',
          collect: false,
          action: '0'
        },
        {
          name: 'debug',
          collect: false,
          action: '0'
        },
        {
          name: 'location',
          collect: false,
          action: '0'
        },
        {
          name: 'domain',
          collect: false,
          action: '0'
        },
        {
          name: 'plugin',
          collect: false,
          action: '0'
        },
        {
          name: 'signature',
          collect: false,
          action: '0'
        },
        {
          name: 'httpsCA',
          collect: false,
          action: '0'
        },
        {
          name: 'wifiProxy',
          collect: false,
          action: '0'
        },
        {
          name: 'multiApk',
          collect: false,
          action: '0'
        },
        {
          name: 'frameAttach',
          collect: false,
          action: '0'
        },
        {
          name: 'dangerApps',
          collect: false,
          action: '0'
        },
        {
          name: 'rootStatus',
          collect: false,
          action: '0'
        },
        {
          name: 'adb',
          collect: false,
          action: '0'
        },
        {
          name: 'vm',
          collect: false,
          action: '0'
        },
        {
          name: 'mockLocation',
          collect: false,
          action: '0'
        },
        {
          name: 'privateCer',
          collect: false,
          action: '0'
        },
        {
          name: 'romCustom',
          collect: false,
          action: '0'
        }
      ]
    }
  },
  created() {
    this.searchList()
  },
  methods: {
    checkboxRadio(item, val) {
      item.action === val ? (item.action = 0) : (item.action = val)
      this.zrgjcai()
    },
    async searchList() {
      const res = await this.$axios.post(
        '/httpServe/ios/danger/selectOne',
        {},
        true
      )
      this.dataArray = res.data
      delete this.dataArray.frequencyAccount
      delete this.dataArray.frequencyIp
      delete this.dataArray.frequencyLocation
      delete this.dataArray.frequencyRestart
      delete this.dataArray.frame
      delete this.dataArray.location
      delete this.dataArray.plugin
      delete this.dataArray.multiApk
      delete this.dataArray.dangerApps
      delete this.dataArray.adb
      delete this.dataArray.mockLocation
      delete this.dataArray.privateCer
      delete this.dataArray.romCustom
      this.$set(this.dataArray, 'use', this.dataArray.use + '')
      let listData = []
      for (const key in this.dataArray) {
        if (this.dataArray[key] >= 16) {
          listData.push({
            name: key,
            collect: true,
            action: this.dataArray[key] - 16
          })
        } else {
          listData.push({
            name: key,
            collect: false,
            action: this.dataArray[key]
          })
        }
      }
      this.listData = listData.splice(1)
      console.log(this.listData)
    },
    test(val) {
      this.zrgjcai()
    },
    async saveSubmit() {
      if (this.dataArray.use == 1) {
        this.$set(this.dataArray, 'use', 1)
      } else {
        this.$set(this.dataArray, 'use', 0)
      }
      const res = await this.$axios.post(
        '/httpServe/ios/danger/update',
        this.dataArray,
        true
      )
      if (this.dataArray.use == 1) {
        this.$set(this.dataArray, 'use', '1')
      } else {
        this.$set(this.dataArray, 'use', '0')
      }
      if (res.code == 200) {
        // this.titleInfo = "保存成功"
        // this.visibaelFlag = true
        this.$message({
          message: '保存成功',
          type: 'success',
          offset: 100
        })
      }
    },
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {
      this.visibaelFlag = false
    },
    // 注入攻击 采集行为
    zrgjcai(val) {
      let listData = this.listData
      listData = JSON.parse(JSON.stringify(listData))
      listData.forEach((item) => {
        item.collect === true ? (item.collect = 16) : (item.collect = 0)
        if (item.collect == 16) {
          this.$set(
            this.dataArray,
            item.name,
            this.dataArray.use == 1 ? item.collect * 1 + item.action * 1 : 0
          )
        } else {
          this.$set(
            this.dataArray,
            item.name,
            this.dataArray.use == 1 ? item.collect * 1 + item.action * 1 : 0
          )
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-form-item__label .ll {
  padding: 0px 0px 0 0 !important;
}
</style>
