<template>
  <div>
    <!-- 签名证书 -->
    <el-form
      :rules="rules"
      ref="ruleForm"
      :model="dataArray"
      label-width="120px"
      class="demo-ruleForm"
      style="padding: 0 109px"
    >
      <el-form-item :label="$t('System.IosConfig.DescriptionFile.organization')+'：'" prop="organization">
        <el-input
          v-model="dataArray.organization"
          style="width: 200px"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.DescriptionFile.topic')+'：'" prop="topic">
        <el-input
          v-model="dataArray.topic"
          style="width: 200px"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.DescriptionFile.url')+'：'" prop="url">
        <el-input
          v-model="dataArray.url"
          style="width: 200px"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.DescriptionFile.scepPayloadIdentifier')+'：'" prop="scepPayloadIdentifier">
        <el-input
          v-model="dataArray.scepPayloadIdentifier"
          style="width: 200px"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.DescriptionFile.accessRights')+'：'" prop="accessRights">
        <el-input
          v-model="dataArray.accessRights"
          style="width: 200px"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.DescriptionFile.checkOutWhenRemoved')+'：'" :required="true">
        <el-checkbox
          true-label="1"
          false-label="0"
          v-model="dataArray.checkOutWhenRemoved"
        >
          <span>{{$t('System.IosConfig.DescriptionFile.yes')}}</span>
        </el-checkbox>
      </el-form-item>
    </el-form>
    <el-button
      style="width: 80px; margin: 20px 0 0 232px"
      @click="saveSubmit"
      size="small"
      type="primary"
      >{{$t('public.Save')}}</el-button
    >
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: {
    dialogInfo,
  },
  data() {
    return {
      visibaelFlag: false,
      titleInfo: "",
      dataArray: {
        organization: "",
        topic: "",
        url: "",
        scepPayloadIdentifier: "",
        accessRights: "",
        checkOutWhenRemoved: "0",
      },
      rules: {
        organization: [
          { required: true, message: this.$t('System.IosConfig.DescriptionFile.organization'), trigger: "blur" },
        ],
        topic: [
          { required: true, message: this.$t('System.IosConfig.DescriptionFile.topic'), trigger: "change" },
        ],
        url: [
          { required: true, message: this.$t('System.IosConfig.DescriptionFile.url'), trigger: "change" },
        ],
        scepPayloadIdentifier: [
          { required: true, message: this.$t('System.IosConfig.DescriptionFile.scepPayloadIdentifier'), trigger: "change" },
        ],
        accessRights: [
          { required: true, message: this.$t('System.IosConfig.DescriptionFile.accessRights'), trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.selectSearch();
  },
  methods: {
    handleClose() {
      this.visibaelFlag = false;
    },
    saveSubmit() {  
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveEdit()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async saveEdit() {
      // if(this.dataArray.checkOutWhenRemoved == 1){
      //   this.$set(this.dataArray,'checkOutWhenRemoved',1)
      // }else {
      //   this.$set(this.dataArray,'checkOutWhenRemoved',0)
      // }
      const res = await this.$axios.post(
        "/httpServe/ios/ota/update",
        this.dataArray,
        true
      );
      if (res.data == 1) {
        // this.titleInfo = "保存成功";
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset:100
        });
      }
    },
    async selectSearch() {
      const res = await this.$axios.post(
        "/httpServe/ios/ota/selectOne",
        {},
        true
      );
      this.dataArray = res.data;
      this.$set(
        this.dataArray,
        "checkOutWhenRemoved",
        this.dataArray.checkOutWhenRemoved + ""
      );
    },
    handleAvatarSuccess1() {},
    test(val) {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-form-item__label {
  font-size: 14px;
}
</style>