<template>
  <div>
    <div class="box">
      <div>
        <el-form :model="form" :rules="rules" ref="form" label-width="155px" class="demo-ruleForm"
          style="padding-left: 91px; padding-top: 10px">
          <el-form-item :label="$t('System.IosConfig.AbmConfig.RegisterProfile')" prop="name">
            <div>
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  {{$t('System.IosConfig.AbmConfig.ConfigurationFile')}}
                </div>
                <img src="../../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
              </el-tooltip>
              <span style="margin-left:15px">
                <el-input size="small" style="width: 350px;" :placeholder="$t('System.IosConfig.AbmConfig.DownloadAddress')" v-model="form.name" show-word-limit>
                  <template slot="prepend">https://</template>
                </el-input>
                <!-- <span>https://</span><el-input size="small" v-model="form.name" style="width: 250px;margin-left: 5px;" placeholder="请输入下载地址"></el-input> -->
              </span>
            </div>
          </el-form-item>
          <!-- <el-form-item label="受监督模式">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                ABM设备开启监督模式
              </div>
              <img src="../../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
            </el-tooltip>
            <el-switch active-value="1" inactive-value="0" @change="supervise" style="padding-left: 15px"
              v-model="permission.crash.use"></el-switch>
          </el-form-item> -->
          <el-form-item :label="$t('System.IosConfig.AbmConfig.AlloDeletion')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('System.IosConfig.AbmConfig.DeletingRegistration')}}<br />{{$t('System.IosConfig.AbmConfig.EnterpriseControl')}}
              </div>
              <img src="../../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
            </el-tooltip>
            <el-switch active-value="1" inactive-value="0" @change="allowDeletion" style="padding-left: 15px"
              v-model="permission.crash2.use"></el-switch>
          </el-form-item>
          <el-form-item :label="$t('System.IosConfig.AbmConfig.AllowDevice')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e"> 
                {{$t('System.IosConfig.AbmConfig.AllowABMregistered')}}<br />{{$t('System.IosConfig.AbmConfig.PairedSuccessfully')}}
              </div>
              <img src="../../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
            </el-tooltip>
            <el-switch active-value="1" inactive-value="0" @change="pairing" style="padding-left: 15px"
              v-model="permission.crash3.use"></el-switch>
          </el-form-item>
          <el-form-item :label="$t('System.IosConfig.AbmConfig.SetAssistant')">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                {{$t('System.IosConfig.AbmConfig.SelectSetAssistant')}}<br />{{$t('System.IosConfig.AbmConfig.OnlyABMcontrolled')}}
              </div>
              <img src="../../../../../assets/question.png" alt="" srcset="" style="padding-left: 8px" />
            </el-tooltip>
            <el-switch active-value="1" inactive-value="0" @change="assistant" style="padding-left: 15px"
              v-model="permission.crash4.use"></el-switch>
          </el-form-item>
          <div v-show="flagtrue">
            <div style="margin-bottom: 10px">
              <el-form-item label="" >
                <el-checkbox-group v-model="skipSetupItems" @change="changeAll" style="margin-left: 40px;">
                  <div style="line-height: 25px;">
                    <el-checkbox style="width: 155px;" label="Location">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Location')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="Appearance">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Appearance')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="Zoom">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Zoom')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="ScreenTime">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.ScreenTime')}}</el-checkbox>
                  </div>
                  <div style="line-height: 25px;">
                    <el-checkbox style="width: 155px;" label="Passcode">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Passcode')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="SIMSetup">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.SIMSetup')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="AppleID">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.AppleID')}} </el-checkbox>
                    <el-checkbox style="width: 155px;" label="Biometric">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Biometric')}}</el-checkbox>
                  </div>
                  <div style="line-height: 25px;">
                    <el-checkbox style="width: 155px;" label="Siri">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Siri')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="AppStore">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.AppStore')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="Privacy">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Privacy')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="Diagnostics">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Diagnostics')}}</el-checkbox>
                  </div>
                  <div style="line-height: 25px;">
                    <el-checkbox style="width: 155px;" label="Payment">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Payment')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="Restore">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Restore')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="SoftwareUpdate">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.SoftwareUpdate')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="DeviceToDeviceMigration">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.DeviceToDeviceMigration')}}</el-checkbox>
                  </div>
                  <div style="line-height: 25px;">
                    <el-checkbox style="width: 155px;" label="Accessibility">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Accessibility')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="WatchMigration">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.WatchMigration')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="MessagingActivationUsingPhoneNumber">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.MessagingActivationUsingPhoneNumber')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="iMessageAndFaceTime">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.iMessageAndFaceTime')}}</el-checkbox>
                  </div>
                  <div style="line-height: 25px;">
                    <el-checkbox style="width: 155px;" label="Safety">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Safety')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="TermsOfAddress">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.TermsOfAddress')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="TOS">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.TOS')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="UpdateCompleted">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.UpdateCompleted')}}</el-checkbox>
                  </div>
                  <div style="line-height: 25px;">
                    <el-checkbox style="width: 155px;" label="RestoreCompleted">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.RestoreCompleted')}}</el-checkbox>
                    <el-checkbox style="width: 155px;" label="Welcome">{{$t('System.IosConfig.AbmConfig.ChangeCheckbox.Welcome')}}</el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
            </div>
          </div>
          <el-form-item>
            <el-button style="width:80px;margin:20px 0 30px 42px;" size="small" type="primary"
              @click="submitForm('form')">{{$t('public.Save')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    crashtest: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      flagtrue: false,
      form: {
        // 系统权限限制
        use: false,
        name: "",
      },
      rules: {
        name: [
          { required: true, message: this.$t('System.IosConfig.AbmConfig.Error'), trigger: 'blur' },
          { max: 200, message: this.$t('System.IosConfig.AbmConfig.Error1'), trigger: 'blur' }
        ],
      },
      permission: {
        crash: {
          use: "1",
        },
        crash2: {
          use: "0",
        },
        crash3: {
          use: "0",
        },
        crash4: {
          use: "0",
        },

      },
      skipSetupItems: [],
    };
  },
  created() { },
  mounted() {
    this.search()
  },
  methods: {
    async search() {
      var param = {};
      const res = await this.$axios.post(
        "/httpServe/abm/profile/selectOne",
        param,
        true
      );
      this.form.name = res.data.url.substring(8)
      this.permission.crash.use = res.data.supervised == true ? '1' : '0'
      this.permission.crash2.use = res.data.mdmRemovable == true ? '1' : '0'
      this.permission.crash3.use = res.data.allowPairing == true ? '1' : '0'
      if (res.data.skipSetupItems.length > 0) {
        this.permission.crash4.use = '1'
        this.flagtrue = true
        this.skipSetupItems = res.data.skipSetupItems
      }

    },
    supervise(val) {
      // 监督模式开启，下面允许开，关。  开1 
      // 非监督模式=0，下面只能开   =1 
      // if (this.permission.crash.use === '0') {
      //   this.permission.crash2.use = '1'
      // } 
    },
    allowDeletion(val) { 
      // if (this.permission.crash.use === '0') {
      //   this.permission.crash2.use = '1'
      // }
    },
    pairing(val) {
    },
    assistant(val) {
      if (this.permission.crash4.use === "1") {
        this.flagtrue = true;
      } else if (this.permission.crash4.use === '0') {
        this.skipSetupItems = []
        this.flagtrue = false;

      }
    },
    checkbox(val) {
    },
    changeAll() {
    },
    async save() {
      if (this.permission.crash4.use === '1' && this.skipSetupItems.length === 0) {
        this.$message({
          message: this.$t('System.IosConfig.AbmConfig.Error2'),
          type: 'error',
          offset: 100
        })
      } else {
        var param = {
          url: 'https://' + this.form.name,
          supervised: true,//this.permission.crash.use === '1' ? true : false
          mdmRemovable: this.permission.crash2.use === '0' ? false : true,
          allowPairing: this.permission.crash3.use === '0' ? false : true,
          skipSetupItems: this.skipSetupItems,
        }; 
        const res = await this.$axios.post(
          "/httpServe/abm/profile/update",
          param,
          true
        );  
      if (res.data == 0) { 
        this.$message({
          message: this.$t('System.IosConfig.AbmToken.Error1'),
          type: "error",
          offset: 100,
        }) 
      }else if(res.data == 1){
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
      }else if(res.data == -1){
        this.$message({
          message: this.$t('System.IosConfig.AbmToken.Error2'),
          type: 'error',
          offset: 100
        })
      }
      }
      

    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.save()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
}
::v-deep .el-form-item__error {
  padding-top: 1px !important;
  padding-left: 104px;
}

// ::v-deep .el-input-group__prepend {
//     background-color: #F5F7FA;
//     color: #909399;
//     vertical-align: middle;
//     display: table-cell;
//     position: relative;
//     border: 1px solid #DCDFE6;
//     border-radius: 4px;
//     padding: 0px 8px;
//     width: 1px;
//     white-space: nowrap;
// }
::v-deep .el-input-group__prepend {
  background-color: #F5F7FA;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid #DCDFE6;
  border-radius: none; //4px
  padding: 0 8px;
  width: 1px;
  white-space: nowrap;
}
</style>