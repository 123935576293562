<template>
  <div>
    <div class="box" style="width: 100%; height: 100%; padding-left: 30px">
      <el-form ref="form" label-width="150px">
        <el-form-item :label="$t('System.IosConfig.SecureDesktops.Label')">
          <div style="display: flex; width: 767px; margin-left: 19px">
            <el-checkbox-group v-model="defaultApp" @change="changCheckbox">
              <el-checkbox
                style="width: 100px"
                v-for="(item, index) in CheckboxList"
                :key="index"
                :label="item.label"
                >{{ $t(`System.IosConfig.SecureDesktops.${item.value}`) }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          style="
            width: 80px;
            margin-left: 169px;
            margin-bottom: 85px;
            margin-top: 22px;
          "
          @click="saveSubmit"
          size="small"
          type="primary"
          >{{ $t("public.Save") }}
        </el-button>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      CheckboxList: [
        {
          label: "message", //消息通知
          value: "消息通知",
          key: 0,
        },
        {
          label: "document", //文档内容
          value: "文档内容",
          key: 1,
        },
        {
          label: "store", //应用商店
          value: "应用商店",
          key: 2,
        },
        {
          label: "help", //使用帮助
          value: "使用帮助",
          key: 3,
        },
        {
          label: "settings", //个人设置
          value: "个人设置",
          key: 4,
        },
        {
          label: "policy", //安全策略
          value: "安全策略",
          key: 5,
        },
        {
          label: "score", //健康评分
          value: "健康评分",
          key: 6,
        },
        {
          label: "access", //安全访问
          value: "安全访问",
          key: 7,
        },
        // {
        //   label:'browser',//安全浏览器
        //   value:"安全浏览器",
        //   key:8,
        // },
        {
          label: "switchX", //切换桌面
          value: "切换桌面",
          key: 9,
        },
      ],
      defaultApp: [], //内置应用
      tableData: [], //安全桌面tab页里的  列表-数组
      otherApp: [],
    };
  },
  mounted() {
    this.searchList();
  },
  methods: {
    changCheckbox(val) {
      let dataAray = [];
      val.forEach((i, index) => {
        let obj = this.CheckboxList.find((item) => {
          return item.label == i;
        });
        dataAray.push(obj);
      });

      console.log(dataAray);

      dataAray.sort((a, b) => a.key - b.key);
      console.log(dataAray, ".....改变后...");
      let arr = [];
      dataAray.forEach((item, index) => {
        arr.push(item.label);
      });
      this.defaultApp = arr;
      console.log(this.defaultApp, "this.defaultApp");
    },
    // 查询
    async searchList() {
      var param = {};
      const res = await this.$axios.post(
        "/httpServe/ios/safeDesk/selectOne",
        param,
        true
      );
      if (res.data) {
        this.defaultApp = res.data.defaultApp;
        this.tableData = res.data.otherApp;
      }
    },
    //更新
    async save() {
      var param = {
        defaultApp: this.defaultApp,
        otherApp: [],
      };
      const res = await this.$axios.post(
        "/httpServe/ios/safeDesk/update",
        param,
        true
      );
      if (res.data == 1) {
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: "success",
          offset: 100,
        });
      }
    },
    //保存
    saveSubmit() {
      this.save();
    },
    //弹框中列表 change的改变事件
    handleSelectionChange(val) {
      this.multipleSelection = [...val];
    },
    // 弹框点X击关闭事件
    handleClose() {
      this.statusVisible = false; //点击出现详情弹框
      //   this.ipt = ''
      //   this.responsedata.currentPage = 1
      //   this.$refs.multipleTables.clearSelection()
    },
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
  },
};
</script>
  
  <style>
</style>