<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="90px" class="demo-ruleForm" style="padding: 0 101px">
      <el-form-item :label="$t('System.IosConfig.AbmToken.DowlonadCertificate')+'：'">
        <!-- 'protected/abm/rsa_ca.pem' -->
        <el-link @click="downFile()" type="primary"> 
          {{$t('System.IosConfig.AbmToken.PublicCertificate')}} 
        </el-link>
      </el-form-item>
      <el-form-item class="form-item" :label="$t('System.IosConfig.AbmToken.UploadToken')+'：'" prop="appName">
        <el-input v-model="form.appName" style="width: 200px; margin: 0 10px 0 0" :disabled="true" size="small">
        </el-input>
        <el-upload action="#" :auto-upload="false" :show-file-list="false" :on-success="handleAvatarSuccess1" class="el-button mar-class-8" style="padding: 0; border: none" :on-change="changeUpload" multiple accept=".p7m">
          <el-button size="small" style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            ">
            <span style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              ">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{$t('System.IosConfig.AbmToken.SelectFile')}}</span>
          </el-button>
        </el-upload>
        <!-- 进度条 -->
        <el-progress
          v-if="flagStatus"
          :percentage="percentage"
          :color="customColorMethod"
          style="width: 230px"
        ></el-progress>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.AbmToken.TokenValidityPeriod')+'：'">
        <span style="color: #807e7e">{{ updateTime }}</span>
      </el-form-item>
    </el-form>
    <el-button style="width: 80px; margin: 20px 0 0 190px" @click="saveSubmit" size="small" type="primary">{{$t('public.Save')}}</el-button>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

  <script>
import dialogInfo from '@/components/promptMessage/index' //提示消息
export default {
  components: {
    dialogInfo
  },
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t('System.IosConfig.Error')))
      } else {
        callback()
      }
    }
    return {
      percentage: 0,
      flagStatus: false,
      updateTime:'',//时间
      acquireUrl:"",//令牌地址
      titleInfo: '',
      visibaelFlag: false,
      form: {
        // 系统权限限制
        use: false,
        appName: ''
      },
      rules: {
        appName: [
          { required: true, message: this.$t('System.IosConfig.CheckDel'), trigger: 'blur' },
          { validator: validateInput, trigger: ['blur', 'change'] }
        ]
      },
      dataArray: {}
    }
  },
  mounted() {
    this.selectSearch();
    // this.acquire()
  },
  methods: {
    async selectSearch() { 
      const res = await this.$axios.post(
        '/httpServe/abm/token/selectOne',
        {}, 
        true
      ) 
      this.updateTime = res.data.accessTokenExpiry.split(' ')[0]
      // .replaceAll('-','/') 
    },
    async acquire( ) {
      var param = { }
      const res = await this.$axios.post(
        '/httpServe/abm/token/getCaKey',
        param,
        true
      )
      this.acquireUrl = res.data 
       
    },
    async downFile() {
      this.acquire()
      var param = {
        path: this.acquireUrl
      } 
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      ) 
      await this.$axios.downloadFile(res.data)
    },
    async saveSubmit() {
      if (this.form.appName == '') { 
        this.$message({
          message: this.$t('System.IosConfig.AbmToken.Error'),
          type: 'error',
          offset: 100
        })
        return
      }
      var param = {
        tokenFile: this.path
      } 
      const res = await this.$axios.post(
        '/httpServe/abm/token/update',
        param,
        true
      )
      console.log(res.data,'-----保存')
      if (res.data == 0) { 
        this.$message({
          message: this.$t('System.IosConfig.AbmToken.Error1'),
          type: "error",
          offset: 100,
        }) 
      }else if(res.data == 1){
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
      }
    },
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },

    //文件上传接口
    async changeUpload(file) {
      this.percentage = 0;
      this.flagStatus = true;
      this.percentage = 0
      this.flagStatus = true
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message.warning(this.$t('System.IosConfig.Error2'))
        return false
      }
      let suffix = this.getFileType(file.name) //获取文件后缀名
      let suffixArray = ['p7m'] //限制的文件类型，根据情况自己定义
    
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t('System.IosConfig.Error3'),
          type: 'error',
          duration: 2000
        })
        this.flagStatus = false;
      } else {
        var renameFile = new File([file.raw], 'emm.p7m', {
          type: file.raw.type
        })
        const form = new FormData()
        // 文件对象
        // form.append("tokenFile", renameFile);
        form.append('files', renameFile)
        form.append('type', 'protected')
        form.append('fileType', '.p7m')
        form.append('moduleName', 'abm')
        const res = await this.$axios.upload(
          '/httpServe/sysFile/fileUp',
          form,
          this.updateProgress,
          true
        ) 
        if(res.data.code == 200){
          this.flagStatus = false;
          this.$message({
            message: this.$t("public.appUpsuccessfully"),
            type: 'success',
            duration: 1000
          })
        }
        this.path = res.data.data[0]  
         
        this.selectSearch() 
        this.$nextTick(() => { 
          this.$set(this.form, 'appName', file.name)
        })
        this.flagStatus = false;
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
       this.percentage = parseInt((e.loaded / e.total) * 100);
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    handleAvatarSuccess1() {},
    determine() {
      this.visibaelFlag = false
    },
    handleClose() {
      this.visibaelFlag = false
    }
  }
}
</script>

  <style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px !important;
}

.form-item {
  margin-bottom: 17px !important;
}

::v-deep .el-form-item__label {
  font-size: 14px;
}
</style>
