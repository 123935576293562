<template>
  <div>
    <!-- 签名证书 -->
    <el-form
      :rules="rules"
      ref="ruleForm"
      :model="dataArray"
      label-width="120px"
      class="demo-ruleForm"
      style="padding: 0 80px;"
    >
      <el-form-item :label="$t('System.IosConfig.MdmCertificate.CertificateName')+'：'">
          <span style="color:#807e7e;">{{ dataArray.name }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.MdmCertificate.CertificateDesc')+'：'">
          <span style="color:#807e7e;">{{$t('System.IosConfig.MdmCertificate.CertificateSpan')}}</span>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.MdmCertificate.DowlonadCertificate')+'：'">
        <el-link @click="downFile('protected/config/p12/mdm.p12')" type="primary">{{$t('System.IosConfig.MdmCertificate.MDMCertificate')}}</el-link>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.MdmCertificate.UploadCertificate')+'：'">
        <el-input
          disabled
          v-model="dataArray.scepPayloadIdentifier"
          style="width: 200px; margin: 0 10px 0 0"
          size="small"
        ></el-input>
        <el-upload
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-success="handleAvatarSuccess1"
          class="el-button mar-class-8"
          style="padding: 0; border: none"
          :on-change="changeUpload"
          multiple
          accept=".p12"
        >
          <el-button
            size="small"
            style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            "
          >
            <span
              style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              "
            >
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{$t('System.IosConfig.MdmCertificate.SelectFile')}}</span>
          </el-button>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.MdmCertificate.Password')+'：'">
        <el-input
          v-model="password"
          style="width: 200px"
          size="small"
          type="password"
        ></el-input>
        <span style="margin-left: 10px;color:#807e7e;">{{$t('System.IosConfig.MdmCertificate.CheckDel')+'：'}}</span>
      </el-form-item>
    </el-form>
    <el-button
      style="width: 80px; margin: 20px 0 0 200px"
      @click="saveSubmit"
      size="small"
      type="primary"
      >{{$t('public.Save')}}</el-button
    >
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: {
    dialogInfo,
  },
  data() {
    return {
      visibaelFlag: false,
      titleInfo: "",
      dataArray: {
        scepPayloadIdentifier:""
      },
      password: "",
      rules: {
        organization: [
          { required: true, message:this.$t('System.IosConfig.MdmCertificate.organizationVoid'), trigger: "blur" },
        ],
        topic: [
          { required: true, message: this.$t('System.IosConfig.MdmCertificate.topicVoid'), trigger: "change" },
        ],
        url: [
          { required: true, message: this.$t('System.IosConfig.MdmCertificate.urlVoid'), trigger: "change" },
        ],
        scepPayloadIdentifier: [
          { required: true, message: this.$t('System.IosConfig.MdmCertificate.scepPayloadVoid'), trigger: "change" },
        ],
        accessRights: [
          { required: true, message: this.$t('System.IosConfig.MdmCertificate.accessRightsVoid'), trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.selectSearch();
  },
  methods: {
    async downFile(url) {
      var param = {
        path: url,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      console.log(res);
      await this.$axios.downloadFile(res.data);
    },
    //文件上传接口
    async changeUpload(file) {
      console.log(file.progress);
      this.percentage = 0;
      this.flagStatus = true;
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message.warning(this.$t('System.IosConfig.Error2'));
        return false;
      }
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ["p12"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t('System.IosConfig.Error3'),
          type: "error",
          duration: 2000,
        });
      } else {
        var renameFile = new File([file.raw], "mdm.p12", { type: file.raw.type });
        const form = new FormData();
        // 文件对象
        form.append("files", renameFile);
        form.append("type", "protected");
        form.append("fileType", "p12");
        form.append("moduleName", "config");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        this.path = res.data.data[0];
        // console.log(file.name)
        this.$nextTick(() => {
          // this.ruleForm.appName = ;
          this.$set(this.dataArray, "scepPayloadIdentifier", file.name);
        });
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    handleAvatarSuccess1() {},
    handleClose() {
      this.visibaelFlag = false;
    },
    saveSubmit() {
      this.saveEdit();
    },
    async saveEdit() {
      var secretKey = $.getSecretKey('mdm.p12')
      let params = {
        pass: this.$utils.common.encrypt(
          this.password,
          "SM4",
          secretKey //基于用户名的密钥
        ),
      };
      const res = await this.$axios.post(
        "/httpServe/ios/cer/mdm/update",
        params,
        true
      );
      if (res.data == 1) {
        // this.titleInfo = "保存成功";
        // this.visibaelFlag = true;
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset:100
        });
      }
    },
    async selectSearch() {
      const res = await this.$axios.post(
        "/httpServe/ios/cer/mdm/selectOne",
        {},
        true
      );
      console.log(res, "222222222");
      this.dataArray = res.data;
    },
    handleAvatarSuccess1() {},
    test(val) {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-form-item__label {
  font-size: 14px;
}
</style>